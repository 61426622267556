import {CodeItem, InstallmentFrequency, RelationTypeWith} from '../types';
import Consts from './consts';

type CODES = {
	/** 布尔值true/false */
	BOOLEAN: CodeItem[];
	/** 布尔值Y/N */
	BOOLEAN_YN: CodeItem[];
	/** 租户类型 */
	TENANT_TYPE: CodeItem[];
	/** 产品支持类型 */
	PRODUCT_FUNCTION_TYPE: CodeItem[];
	CURRENCY: CodeItem[];
	/** 币种符号 */
	CURRENCY_SIGN: CodeItem[];
	/** 合约状态 */
	AGREEMENT_STATUS: CodeItem[];
	/** 支持的页面主题 */
	THEME: CodeItem[];
	/** 课程类型 */
	COURSE_TYPE: CodeItem[];
	/** 课程状态 */
	COURSE_STATUS: CodeItem[];

	/** 订单状态 */
	POLICY_STATUS: CodeItem[];

	/** 订单明细状态 */
	DETAIL_STATUS: CodeItem[];

	/** 性别 */
	GENDER: CodeItem[];
	/** 婚姻状况 */
	MARITAL_STATUS: CodeItem[];
	/** 保单递送方式 */
	DELIVERY_METHOD: CodeItem[];
	/** 国籍 */
	NATIONALITY: CodeItem[];
	/** 证件类型 */
	ID_TYPE: CodeItem[];
	/** 税收类型 */
	TAX_TYPE: CodeItem[];
	/** 客户类型 */
	CUSTOMER_TYPE: CodeItem[];

	STATEMENT_SOURCE_TYPE: CodeItem[];

	FILE_TYPE: CodeItem[];
	/** 国籍 中英文 */
	NATIONALITYEN: CodeItem[];
	CHARGE_PERIOD: CodeItem[];
	COVERAGE_PERIOD: CodeItem[];
	RELATION_TYPE: CodeItem[];
	DELIVERYMETHOD: CodeItem[];
	EDUCATIONALDEGREE:CodeItem[];
	readonly [propName: string]: CodeItem[];
};

export default {
	BOOLEAN: [{ l: '是', v: true }, { l: '否', v: false }],
	BOOLEAN_YN: [{ l: '是', v: 'Y' }, { l: '否', v: 'N' }],
	TENANT_TYPE: [
		{ l: '保险公司', v: Consts.TENANT_TYPE.INSURANCE_COMPANY },
		{ l: '经纪公司', v: Consts.TENANT_TYPE.BROKER },
		{ l: '代理公司', v: Consts.TENANT_TYPE.AGENT },
		{ l: '虚拟租户', v: Consts.TENANT_TYPE.VIRTUAL },
		{ l: '系统持有人', v: Consts.TENANT_TYPE.SYSTEM_OWNER }
	],
	PRODUCT_FUNCTION_TYPE: [{ l: '仅计划书', v: 1 }, { l: '仅电投', v: 2 }, { l: '电投+计划书', v: 3 }],
	AGREEMENT_STATUS: [
		{ v: 1, l: '定义中' },
		{ v: 2, l: '申请中' },
		{ v: 3, l: '审核通过' },
		{ v: 4, l: '审核驳回' },
		{ v: 5, l: '已关闭' }
	],
	CURRENCY: [{ v: 'CNY', l: '人民币' }],
	CURRENCY_SIGN: [
		{ v: 'CNY', l: '¥' },
		{ v: 'USD', l: '$' },
		{ v: 'EUR', l: '€' },
		{ v: 'HKD', l: 'HK$' },
		{ v: 'GBP', l: '£' },
		{ v: 'JPY', l: '¥' },
		{ v: 'TWD', l: 'NT$' }
	],
	THEME: [
		{ l: '中国红', v: 'china' },
		{ l: '波特兰红', v: 'portland' },
		{ l: '泰格橙', v: 'tiger' },
		{ l: '奥林匹克蓝', v: 'olympics' },
		{ l: '中汇国际黄', v: 'zhgj' }
	],
	COURSE_TYPE: [{ v: 1, l: '必修课程' }, { v: 2, l: '选修课程' }, { v: 3, l: '线下课程' }, { v: 4, l: '视频' }],
	COURSE_STATUS: [{ v: 1, l: '启用' }, { v: 2, l: '禁用' }],
	POLICY_STATUS: [
		{ l: '报价中', v: '1' },
		{ l: '承保', v: '2' },
		{ l: '待支付', v: '3' },
		{ l: '计划书', v: '5' },
		{ l: '已支付未出单', v: '7' },
		{ l: '出单中', v: '8' },
		{ l: '支付失败', v: '87' },
		{ l: '撤单', v: '96' },
		{ l: '退保', v: '98' },
		{ l: '满期', v: '99' },
		{ l: '拒保', v: '97' }
	],
	DETAIL_STATUS: [
		{ v: '1', l: '已删除' },
		{ v: '11', l: '待提交' },
		{ v: '51', l: '投保提交' },
		{ v: '101', l: '人工核保中' },
		{ v: '102', l: '投保失败' },
		{ v: '106', l: '自核通过' },
		{ v: '107', l: '核保通过' },
		{ v: '109', l: '其他' },
		{ v: '110', l: '核保中' },
		{ v: '112', l: '自核失败' },
        { v: '113', l: '延期' },
        { v: '115', l: '拒保' },
        { v: '116', l: '人工复核' },
		{ v: '120', l: '退费中' },
		{ v: '121', l: '退费成功' },
		{ v: '122', l: '退费失败' },
		{ v: '203', l: '回执审核中' },
		{ v: '204', l: '已回访' },
		{ v: '201', l: '已承保' },
		{ v: '202', l: '已回执' },
		{ v: '114', l: '待支付' },
		{ v: '104', l: '转账收费中' },
		{ v: '105', l: '转账失败' },
		{ v: '108', l: '转账成功' },
		{ v: '103', l: '撤单' },
		{ v: '501', l: '失效' },
		{ v: '502', l: '终止' },
		{ v: '504', l: '退保' }
	],
	GENDER: [{ v: Consts.GENDER.MALE, l: '男' }, { v: Consts.GENDER.FEMALE, l: '女' }],
	MARITAL_STATUS: [{ v: 1, l: '未婚' }, { v: 2, l: '已婚' }, { v: 3, l: '离异' }, { v: 4, l: '丧偶' }],
	DELIVERY_METHOD: [{ v: 1, l: '电子保单' }, { v: 2, l: '纸质保单' }],
	NATIONALITY: (() => {
		const data = {
			CHN: '中国',
			TWN: '中国台湾',
			HKG: '中国香港',
			MAC: '中国澳门',
			ALB: '阿尔巴尼亚',
			DZA: '阿尔及利亚',
			AFG: '阿富汗',
			ARG: '阿根廷',
			ARE: '阿联酋',
			ABW: '阿鲁巴',
			OMN: '阿曼',
			AZE: '阿塞拜疆',
			EGY: '埃及',
			ETH: '埃塞俄比亚',
			IRL: '爱尔兰',
			EST: '爱沙尼亚',
			AND: '安道尔',
			AGO: '安哥拉',
			AIA: '安圭拉',
			ATG: '安提瓜和巴布达',
			AUT: '奥地利',
			ALA: '奥兰群岛',
			AUS: '澳大利亚',
			BRB: '巴巴多斯',
			PNG: '巴布亚新几内亚',
			BHS: '巴哈马',
			PAK: '巴基斯坦',
			PRY: '巴拉圭',
			PSE: '巴勒斯坦',
			BHR: '巴林',
			PAN: '巴拿马',
			BRA: '巴西',
			BLR: '白俄罗斯',
			BMU: '百慕大',
			BGR: '保加利亚',
			MNP: '北马里亚纳群岛',
			BEN: '贝宁',
			BEL: '比利时',
			ISL: '冰岛',
			PRI: '波多黎各',
			POL: '波兰',
			BIH: '波斯尼亚和黑塞哥维那',
			BOL: '玻利维亚',
			BLZ: '伯利兹',
			BWA: '博茨瓦纳',
			BTN: '不丹',
			BFA: '布基纳法索',
			BDI: '布隆迪',
			BVT: '布韦岛',
			PRK: '朝鲜',
			GNQ: '赤道几内亚',
			DNK: '丹麦',
			DEU: '德国',
			TLS: '东帝汶',
			TGO: '多哥',
			DOM: '多米尼加',
			DMA: '多米尼克',
			RUS: '俄罗斯',
			ECU: '厄瓜多尔',
			ERI: '厄立特里亚',
			FRA: '法国',
			FRO: '法罗群岛',
			PYF: '法属波利尼西亚',
			GUF: '法属圭亚那',
			ATF: '法属南部领地',
			MAF: '法属圣马丁',
			VAT: '梵蒂冈',
			PHL: '菲律宾',
			FJI: '斐济群岛',
			FIN: '芬兰',
			CPV: '佛得角',
			GMB: '冈比亚',
			COG: '刚果-布',
			COD: '刚果-金',
			COL: '哥伦比亚',
			CRI: '哥斯达黎加',
			GRD: '格林纳达',
			GRL: '格陵兰',
			GEO: '格鲁吉亚',
			GGY: '根西岛',
			CUB: '古巴',
			GLP: '瓜德罗普',
			GUM: '关岛',
			GUY: '圭亚那',
			KAZ: '哈萨克斯坦',
			HTI: '海地',
			KOR: '韩国',
			NLD: '荷兰',
			BES: '荷兰加勒比区',
			HMD: '赫德岛和麦克唐纳群岛',
			MNE: '黑山',
			HND: '洪都拉斯',
			KIR: '基里巴斯',
			DJI: '吉布提',
			KGZ: '吉尔吉斯斯坦',
			GIN: '几内亚',
			GNB: '几内亚比绍',
			CAN: '加拿大',
			GHA: '加纳',
			GAB: '加蓬',
			KHM: '柬埔寨',
			CZE: '捷克',
			ZWE: '津巴布韦',
			CMR: '喀麦隆',
			QAT: '卡塔尔',
			CYM: '开曼群岛',
			CCK: '科科斯群岛',
			COM: '科摩罗',
			CIV: '科特迪瓦',
			KWT: '科威特',
			HRV: '克罗地亚',
			KEN: '肯尼亚',
			COK: '库克群岛',
			LVA: '拉脱维亚',
			LSO: '莱索托',
			LAO: '老挝',
			LBN: '黎巴嫩',
			LTU: '立陶宛',
			LBR: '利比里亚',
			LBY: '利比亚',
			LIE: '列支敦士登',
			REU: '留尼汪',
			LUX: '卢森堡',
			RWA: '卢旺达',
			ROU: '罗马尼亚',
			MDG: '马达加斯加',
			IMN: '马恩岛',
			MDV: '马尔代夫',
			FLK: '马尔维纳斯群岛-福克兰',
			MLT: '马耳他',
			MWI: '马拉维',
			MYS: '马来西亚',
			MLI: '马里',
			MKD: '马其顿',
			MHL: '马绍尔群岛',
			MTQ: '马提尼克',
			MYT: '马约特',
			MUS: '毛里求斯',
			MRT: '毛里塔尼亚',
			USA: '美国',
			UMI: '美国本土外小岛屿',
			ASM: '美属萨摩亚',
			VIR: '美属维尔京群岛',
			MNG: '蒙古国',
			MSR: '蒙塞拉特岛',
			BGD: '孟加拉国',
			PER: '秘鲁',
			FSM: '密克罗尼西亚联邦',
			MMR: '缅甸',
			MDA: '摩尔多瓦',
			MAR: '摩洛哥',
			MCO: '摩纳哥',
			MOZ: '莫桑比克',
			MEX: '墨西哥',
			NAM: '纳米比亚',
			ZAF: '南非',
			ATA: '南极洲',
			SGS: '南乔治亚岛和南桑威奇群岛',
			SSD: '南苏丹',
			NRU: '瑙鲁',
			NPL: '尼泊尔',
			NIC: '尼加拉瓜',
			NER: '尼日尔',
			NGA: '尼日利亚',
			NIU: '纽埃',
			NOR: '挪威',
			NFK: '诺福克岛',
			PLW: '帕劳',
			PCN: '皮特凯恩群岛',
			PRT: '葡萄牙',
			JPN: '日本',
			SWE: '瑞典',
			CHE: '瑞士',
			SLV: '萨尔瓦多',
			WSM: '萨摩亚',
			SRB: '塞尔维亚',
			SLE: '塞拉利昂',
			SEN: '塞内加尔',
			CYP: '塞浦路斯',
			SYC: '塞舌尔',
			SAU: '沙特阿拉伯',
			BLM: '圣巴泰勒米岛',
			CXR: '圣诞岛',
			STP: '圣多美和普林西比',
			SHN: '圣赫勒拿',
			KNA: '圣基茨和尼维斯',
			LCA: '圣卢西亚',
			SMR: '圣马力诺',
			SPM: '圣皮埃尔和密克隆',
			VCT: '圣文森特和格林纳丁斯',
			LKA: '斯里兰卡',
			SVK: '斯洛伐克',
			SVN: '斯洛文尼亚',
			SJM: '斯瓦尔巴群岛和扬马延岛',
			SWZ: '斯威士兰',
			SDN: '苏丹',
			SUR: '苏里南',
			SLB: '所罗门群岛',
			SOM: '索马里',
			TJK: '塔吉克斯坦',
			THA: '泰国',
			TZA: '坦桑尼亚',
			TON: '汤加',
			TCA: '特克斯和凯科斯群岛',
			TTO: '特立尼达和多巴哥',
			TUN: '突尼斯',
			TUV: '图瓦卢',
			TUR: '土耳其',
			TKM: '土库曼斯坦',
			TKL: '托克劳',
			WLF: '瓦利斯和富图纳',
			VUT: '瓦努阿图',
			GTM: '危地马拉',
			VEN: '委内瑞拉',
			BRN: '文莱',
			UGA: '乌干达',
			UKR: '乌克兰',
			URY: '乌拉圭',
			UZB: '乌兹别克斯坦',
			ESP: '西班牙',
			ESH: '西撒哈拉',
			GRC: '希腊',
			SGP: '新加坡',
			NCL: '新喀里多尼亚',
			NZL: '新西兰',
			HUN: '匈牙利',
			SYR: '叙利亚',
			JAM: '牙买加',
			ARM: '亚美尼亚',
			YEM: '也门',
			IRQ: '伊拉克',
			IRN: '伊朗',
			ISR: '以色列',
			ITA: '意大利',
			IND: '印度',
			IDN: '印度尼西亚',
			GBR: '英国',
			VGB: '英属维尔京群岛',
			IOT: '英属印度洋领地',
			JOR: '约旦',
			VNM: '越南',
			ZMB: '赞比亚',
			JEY: '泽西岛',
			TCD: '乍得',
			GIB: '直布罗陀',
			CHL: '智利',
			CAF: '中非'
		} as { [propName: string]: string };
		return Object.keys(data).map(key => {
			return { v: key, l: data[key] };
		});
	})(),
	//客户类型
	CUSTOMER_TYPE: [
		{ v: Consts.CUSTOMER_TYPE.HOLDER, l: '投保人' },
		{ v: Consts.CUSTOMER_TYPE.INSURED, l: '被保人' },
		{ v: Consts.CUSTOMER_TYPE.BENEFICIARY, l: '受益人' },
		{ v: Consts.CUSTOMER_TYPE.PROSPECT, l: '准客户' },
	],
	//证件类型
	ID_TYPE: [
		{ v: Consts.ID_TYPE.ID_CARD, l: '身份证' },
		{ v: Consts.ID_TYPE.FAMILY_REGISTER, l: '户口本' },
		{ v: Consts.ID_TYPE.BIRTH_CERTIFICATE, l: '出生证明' },
		{ v: Consts.ID_TYPE.DRIVE_LICENSE, l: '驾驶证' },
		{ v: Consts.ID_TYPE.MILITARY_ID, l: '军官证' },
		{ v: Consts.ID_TYPE.PASSPORT, l: '护照' },
		{ v: Consts.ID_TYPE.HK_PASS, l: '港澳通行证' },
		{ v: Consts.ID_TYPE.TW_PASS, l: '台湾通行证' },
		// { v: Consts.ID_TYPE.FOREIGN_PASSPORT, l: '外国护照' },
		{ v: Consts.ID_TYPE.FOREIGN_REGISTER_CARD, l: '居住证' },
		{ v: Consts.ID_TYPE.HK_MAINLAND, l: '港澳居民来往内地通行证' },
		{ v: Consts.ID_TYPE.TW_MAINLAND, l: '台湾居民来往大陆通行证' },
		{ v: Consts.ID_TYPE.HK_RESIDENT_PERMIT, l: '港澳居民居住证' },
		{ v: Consts.ID_TYPE.TW_RESIDENT_PERMIT, l: '台湾居民居住证' },
		{ v: Consts.ID_TYPE.PERMANENT_RESIDENT_PERMIT, l: '外国人永久居留身份证' },
		{ v: Consts.ID_TYPE.TEMPORARY_ID_CARD, l: '临时身份证' },
		{ v: Consts.ID_TYPE.SOLDIER_ID_CARD, l: '士兵证' },
		{ v: Consts.ID_TYPE.AIWAN_GANGAO_LIVING_CARD, l: '港澳台居民居住证' },
		{ v: Consts.ID_TYPE.OTHERS, l: '其他' }
	],
	TAX_TYPE_FXJK: [
		{ v: Consts.TAX_TYPE.CHINA, l: '仅为中国税收居民' },
	],
	TAX_TYPE: [
		{ v: Consts.TAX_TYPE.CHINA, l: '仅为中国税收居民' },
		{ v: Consts.TAX_TYPE.NOT_CHINA, l: '仅为非居民' },
		{ v: Consts.TAX_TYPE.BOTH, l: '既是中国税收居民又是其他国家（地区）税收居民' }
	],
	PAYMENT_METHOD: [
		{ v: Consts.PAYMENT_METHOD.CASH, l: '现金' },
		{ v: Consts.PAYMENT_METHOD.CHEQUE, l: '支票' },
		{ v: Consts.PAYMENT_METHOD.REAL_TIME, l: '银行转账' },//时时转账
		{ v: Consts.PAYMENT_METHOD.CREDIT_CARD, l: '信用卡' },
		{ v: Consts.PAYMENT_METHOD.WECHAT_PAY, l: '微信支付' },
		{ v: Consts.PAYMENT_METHOD.BATCH_OFFLINE, l: '线下支付' },
		{ v: Consts.PAYMENT_METHOD.UNION_WEB_PAY, l: '银联网页支付' },
		{ v: Consts.PAYMENT_METHOD.OTHER, l: '其他' },
	],
	COVERAGE_TYPE: [
		{ v: Consts.COVERAGE_TYPE.MAIN, l: '主险' },
		{ v: Consts.COVERAGE_TYPE.RIDER, l: '附加险' },
	],
	STATEMENT_SOURCE_TYPE: [{ v: '0', l: 'toA渠道' }, { v: '3', l: 'toAtoC渠道' }, { v: '4', l: 'toC渠道' }],
	FILE_TYPE: [{ v: 'image/jpeg', l: 'JPEG' }, { v: 'image/png', l: 'PNG' }, { v: 'application/pdf', l: 'PDF' }],
	NATIONALITYEN: (() => {
		const data = {
			AND: '安道尔-Andorra',
			ARE: '阿联酋-United Arab Emirates',
			AFG: '阿富汗-Afghanistan',
			ATG: '安提瓜和巴布达-Antigua & Barbuda',
			AIA: '安圭拉-Anguilla',
			ALB: '阿尔巴尼亚-Albania',
			ARM: '亚美尼亚-Armenia',
			AGO: '安哥拉-Angola',
			ATA: '南极洲-Antarctica',
			ARG: '阿根廷-Argentina',
			ASM: '美属萨摩亚-American Samoa',
			AUT: '奥地利-Austria',
			AUS: '澳大利亚-Australia',
			ABW: '阿鲁巴-Aruba',
			ALA: '奥兰群岛-aland Island',
			AZE: '阿塞拜疆-Azerbaijan',
			BIH: '波黑-Bosnia & Herzegovina',
			BRB: '巴巴多斯-Barbados',
			BGD: '孟加拉-Bangladesh',
			BEL: '比利时-Belgium',
			BFA: '布基纳法索-Burkina',
			BGR: '保加利亚-Bulgaria',
			BHR: '巴林-Bahrain',
			BDI: '布隆迪-Burundi',
			BEN: '贝宁-Benin',
			BLM: '圣巴泰勒米岛-Saint Barthélemy',
			BMU: '百慕大-Bermuda',
			BRN: '文莱-Brunei',
			BOL: '玻利维亚-Bolivia',
			BES: '荷兰加勒比区-Caribbean Netherlands',
			BRA: '巴西-Brazil',
			BHS: '巴哈马-The Bahamas',
			BTN: '不丹-Bhutan',
			BVT: '布韦岛-Bouvet Island',
			BWA: '博茨瓦纳-Botswana',
			BLR: '白俄罗斯-Belarus',
			BLZ: '伯利兹-Belize',
			CAN: '加拿大-Canada',
			CCK: '科科斯群岛-Cocos Islands',
			COD: '刚果-Democratic Republic of the Congo',
			CAF: '中非-Central African Republic',
			COG: '刚果-Republic of the Congo',
			CHE: '瑞士-Switzerland',
			CIV: '科特迪瓦-C?te d\'Ivoire',
			COK: '库克群岛-Cook Islands',
			CHL: '智利-Chile',
			CMR: '喀麦隆-Cameroon',
			CHN: '中国-内地 China',
			COL: '哥伦比亚-Colombia',
			CRI: '哥斯达黎加-Costa Rica',
			CUB: '古巴-Cuba',
			CPV: '佛得角-Cape Verde',
			CXR: '圣诞岛-Christmas Island',
			CYP: '塞浦路斯-Cyprus',
			CZE: '捷克-Czech Republic',
			DEU: '德国-Germany',
			DJI: '吉布提-Djibouti',
			DNK: '丹麦-Denmark',
			DMA: '多米尼克-Dominica',
			DOM: '多米尼加-Dominican Republic',
			DZA: '阿尔及利亚-Algeria',
			ECU: '厄瓜多尔-Ecuador',
			EST: '爱沙尼亚-Estonia',
			EGY: '埃及-Egypt',
			ESH: '西撒哈拉-Western Sahara',
			ERI: '厄立特里亚-Eritrea',
			ESP: '西班牙-Spain',
			ETH: '埃塞俄比亚-Ethiopia',
			FIN: '芬兰-Finland',
			FJI: '斐济群岛-Fiji',
			FLK: '马尔维纳斯群岛-Falkland Islands',
			FSM: '密克罗尼西亚联邦-Federated States of Micronesia',
			FRO: '法罗群岛-Faroe Islands',
			FRA: '法国-France',
			GAB: '加蓬-Gabon',
			GBR: '英国-Great Britain',
			GRD: '格林纳达-Grenada',
			GEO: '格鲁吉亚-Georgia',
			GUF: '法属圭亚那-French Guiana',
			GGY: '根西岛-Guernsey',
			GHA: '加纳-Ghana',
			GIB: '直布罗陀-Gibraltar',
			GRL: '格陵兰-Greenland',
			GMB: '冈比亚-Gambia',
			GIN: '几内亚-Guinea',
			GLP: '瓜德罗普-Guadeloupe',
			GNQ: '赤道几内亚-Equatorial Guinea',
			GRC: '希腊-Greece',
			SGS: '南乔治亚岛和南桑威奇群岛-South Georgia and the South Sandwich Islands',
			GTM: '危地马拉-Guatemala',
			GUM: '关岛-Guam',
			GNB: '几内亚比绍-Guinea-Bissau',
			GUY: '圭亚那-Guyana',
			HKG: '香港-Hong Kong',
			HMD: '赫德岛和麦克唐纳群岛-Heard Island and McDonald Islands',
			HND: '洪都拉斯-Honduras',
			HRV: '克罗地亚-Croatia',
			HTI: '海地-Haiti',
			HUN: '匈牙利-Hungary',
			IDN: '印尼-Indonesia',
			IRL: '爱尔兰-Ireland',
			ISR: '以色列-Israel',
			IMN: '马恩岛-Isle of Man',
			IND: '印度-India',
			IOT: '英属印度洋领地-British Indian Ocean Territory',
			IRQ: '伊拉克-Iraq',
			IRN: '伊朗-Iran',
			ISL: '冰岛-Iceland',
			ITA: '意大利-Italy',
			JEY: '泽西岛-Jersey',
			JAM: '牙买加-Jamaica',
			JOR: '约旦-Jordan',
			JPN: '日本-Japan',
			KEN: '肯尼亚-Kenya',
			KGZ: '吉尔吉斯斯坦-Kyrgyzstan',
			KHM: '柬埔寨-Cambodia',
			KIR: '基里巴斯-Kiribati',
			COM: '科摩罗-The Comoros',
			KNA: '圣基茨和尼维斯-St. Kitts & Nevis',
			PRK: '朝鲜-北朝鲜 North Korea',
			KOR: '韩国-南朝鲜 South Korea',
			KWT: '科威特-Kuwait',
			CYM: '开曼群岛-Cayman Islands',
			KAZ: '哈萨克斯坦-Kazakhstan',
			LAO: '老挝-Laos',
			LBN: '黎巴嫩-Lebanon',
			LCA: '圣卢西亚-St. Lucia',
			LIE: '列支敦士登-Liechtenstein',
			LKA: '斯里兰卡-Sri Lanka',
			LBR: '利比里亚-Liberia',
			LSO: '莱索托-Lesotho',
			LTU: '立陶宛-Lithuania',
			LUX: '卢森堡-Luxembourg',
			LVA: '拉脱维亚-Latvia',
			LBY: '利比亚-Libya',
			MAR: '摩洛哥-Morocco',
			MCO: '摩纳哥-Monaco',
			MDA: '摩尔多瓦-Moldova',
			MNE: '黑山-Montenegro',
			MAF: '法属圣马丁-Saint Martin',
			MDG: '马达加斯加-Madagascar',
			MHL: '马绍尔群岛-Marshall islands',
			MKD: '马其顿-Republic of Macedonia',
			MLI: '马里-Mali',
			MMR: '缅甸-Myanmar',
			MNG: '蒙古国-蒙古 Mongolia',
			MAC: '澳门-Macao',
			MNP: '北马里亚纳群岛-Northern Mariana Islands',
			MTQ: '马提尼克-Martinique',
			MRT: '毛里塔尼亚-Mauritania',
			MSR: '蒙塞拉特岛-Montserrat',
			MLT: '马耳他-Malta',
			MUS: '毛里求斯-Mauritius',
			MDV: '马尔代夫-Maldives',
			MWI: '马拉维-Malawi',
			MEX: '墨西哥-Mexico',
			MYS: '马来西亚-Malaysia',
			MOZ: '莫桑比克-Mozambique',
			NAM: '纳米比亚-Namibia',
			NCL: '新喀里多尼亚-New Caledonia',
			NER: '尼日尔-Niger',
			NFK: '诺福克岛-Norfolk Island',
			NGA: '尼日利亚-Nigeria',
			NIC: '尼加拉瓜-Nicaragua',
			NLD: '荷兰-Netherlands',
			NOR: '挪威-Norway',
			NPL: '尼泊尔-Nepal',
			NRU: '瑙鲁-Nauru',
			NIU: '纽埃-Niue',
			NZL: '新西兰-New Zealand',
			OMN: '阿曼-Oman',
			PAN: '巴拿马-Panama',
			PER: '秘鲁-Peru',
			PYF: '法属波利尼西亚-French polynesia',
			PNG: '巴布亚新几内亚-Papua New Guinea',
			PHL: '菲律宾-The Philippines',
			PAK: '巴基斯坦-Pakistan',
			POL: '波兰-Poland',
			SPM: '圣皮埃尔和密克隆-Saint-Pierre and Miquelon',
			PCN: '皮特凯恩群岛-Pitcairn Islands',
			PRI: '波多黎各-Puerto Rico',
			PSE: '巴勒斯坦-Palestinian territories',
			PRT: '葡萄牙-Portugal',
			PLW: '帕劳-Palau',
			PRY: '巴拉圭-Paraguay',
			QAT: '卡塔尔-Qatar',
			REU: '留尼汪-Réunion',
			ROU: '罗马尼亚-Romania',
			SRB: '塞尔维亚-Serbia',
			RUS: '俄罗斯-Russian Federation',
			RWA: '卢旺达-Rwanda',
			SAU: '沙特阿拉伯-Saudi Arabia',
			SLB: '所罗门群岛-Solomon Islands',
			SYC: '塞舌尔-Seychelles',
			SDN: '苏丹-Sudan',
			SWE: '瑞典-Sweden',
			SGP: '新加坡-Singapore',
			SHN: '圣赫勒拿-St. Helena & Dependencies',
			SVN: '斯洛文尼亚-Slovenia',
			SJM: '斯瓦尔巴群岛和-Template:Country data SJM Svalbard',
			SVK: '斯洛伐克-Slovakia',
			SLE: '塞拉利昂-Sierra Leone',
			SMR: '圣马力诺-San Marino',
			SEN: '塞内加尔-Senegal',
			SOM: '索马里-Somalia',
			SUR: '苏里南-Suriname',
			SSD: '南苏丹-South Sudan',
			STP: '圣多美和普林西比-Sao Tome & Principe',
			SLV: '萨尔瓦多-El Salvador',
			SYR: '叙利亚-Syria',
			SWZ: '斯威士兰-Swaziland',
			TCA: '特克斯和凯科斯群岛-Turks & Caicos Islands',
			TCD: '乍得-Chad',
			ATF: '法属南部领地-French Southern Territories',
			TGO: '多哥-Togo',
			THA: '泰国-Thailand',
			TJK: '塔吉克斯坦-Tajikistan',
			TKL: '托克劳-Tokelau',
			TLS: '东帝汶-Timor',
			TKM: '土库曼斯坦-Turkmenistan',
			TUN: '突尼斯-Tunisia',
			TON: '汤加-Tonga',
			TUR: '土耳其-Turkey',
			TTO: '特立尼达和多巴哥-Trinidad & Tobago',
			TUV: '图瓦卢-Tuvalu',
			TWN: '中华台湾-Taiwan',
			TZA: '坦桑尼亚-Tanzania',
			UKR: '乌克兰-Ukraine',
			UGA: '乌干达-Uganda',
			UMI: '美国本土外小岛屿-United States Minor Outlying Islands',
			USA: '美国-United States of America',
			URY: '乌拉圭-Uruguay',
			UZB: '乌兹别克斯坦-Uzbekistan',
			VAT: '梵蒂冈-Vatican City',
			VCT: '圣文森特和格林纳丁斯-St. Vincent & the Grenadines',
			VEN: '委内瑞拉-Venezuela',
			VGB: '英属维尔京群岛-British Virgin Islands',
			VIR: '美属维尔京群岛-United States Virgin Islands',
			VNM: '越南-Vietnam',
			VUT: '瓦努阿图-Vanuatu',
			WLF: '瓦利斯和富图纳-Wallis and Futuna',
			WSM: '萨摩亚-Samoa',
			YEM: '也门-Yemen',
			MYT: '马约特-Mayotte',
			ZAF: '南非-South Africa',
			ZMB: '赞比亚-Zambia',
			ZWE: '津巴布韦-Zimbabwe'
		} as { [propName: string]: string };
		return Object.keys(data).map(key => {
			return { v: key, l: data[key] };
		});
	})(),
	CHARGE_PERIOD: (() => {
		const data = [] as CodeItem[];
		new Array(100).fill(1).forEach((item: AnalyserNode, index: number) => {
			data.push({
				v: `Y${index + 1}`, l: `${index + 1}年`
			})
			data.push({
				v: `A${index + 1}`, l: `至${index + 1}周岁`
			})
		});
		data.push({
			v: `S1`, l: `趸缴`
		});
		data.push({
			v: `WL`, l: `交终身`
		})
		return data;
	})(),
	COVERAGE_PERIOD: (() => {
		const data = [] as CodeItem[];
		new Array(120).fill(1).forEach((item: AnalyserNode, index: number) => {
			data.push({
				v: `Y${index + 1}`, l: `${index + 1}年`
			})
			data.push({
				v: `A${index + 1}`, l: `至${index + 1}周岁`
			})
		});
		new Array(12).fill(1).forEach((item: AnalyserNode, index: number) => {
			data.push({
				v: `M${index + 1}`, l: `${index + 1}月`
			})
		});
		new Array(366).fill(1).forEach((item: AnalyserNode, index: number) => {
			data.push({
				v: `D${index + 1}`, l: `${index + 1}天`
			})
		});
		data.push({
			v: `WL`, l: `保终身`
		})
		return data;
	})(),
	PAYMENT_FREQ: [
		{ l: '趸交', v: InstallmentFrequency.SINGLE_PREMIUM },
		{ l: '年交', v: InstallmentFrequency.YEARLY },
		{ l: '半年交', v: InstallmentFrequency.HALF_YEAR },
		{ l: '季交', v: InstallmentFrequency.QUARTERLY },
		{ l: '月交', v: InstallmentFrequency.MONTHLY },
		{ l: '不定期交', v: InstallmentFrequency.IRREGULAR }
	] as CodeItem[],
	RELATION_TYPE: [
		{ v: RelationTypeWith.SELF, l: '本人' },
		{ v: RelationTypeWith.SPOUSE, l: '配偶' },
		{ v: RelationTypeWith.CHILD, l: '子女' },
		{ v: RelationTypeWith.PARENTS, l: '父母' },
		{ v: RelationTypeWith.WIFE, l: '妻子' },
		{ v: RelationTypeWith.HUSBAND, l: '丈夫' },
		{ v: RelationTypeWith.SON, l: '儿子' },
		{ v: RelationTypeWith.DAUGHTER, l: '女儿' },
		{ v: RelationTypeWith.FATHER, l: '父亲' },
		{ v: RelationTypeWith.MOTHER, l: '母亲' },
		{ v: RelationTypeWith.GRAND_FATHER_PARTRILOCAL, l: '祖父' },
		{ v: RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, l: '祖母' },
		{ v: RelationTypeWith.GRAND_FATHER_MATRIARCHAL, l: '外祖父' },
		{ v: RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, l: '外祖母' },
		{ v: RelationTypeWith.GRAND_SON_MATRIARCHAL, l: '外孙' },
		{ v: RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, l: '外孙女' },
		{ v: RelationTypeWith.GRAND_SON_PARTRILOCAL, l: '孙子' },
		{ v: RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, l: '孙女' },
		{ v: RelationTypeWith.SON_1, l: '大儿子' },
		{ v: RelationTypeWith.SON_99, l: '小儿子' },
		{ v: RelationTypeWith.DAUGHTER_1, l: '大女儿' },
		{ v: RelationTypeWith.DAUGHTER_99, l: '小女儿' },
	] as CodeItem[],
	DELIVERYMETHOD: [
		{ v: '1', l: '电子保单' },
		{ v: '2', l: '纸质保单' },
		{ v: '3', l: '电子保单+纸质保单' },
	] as CodeItem[],
	EDUCATIONALDEGREE: [
		{ v: '0', l: '博士' },
		{ v: '1', l: '硕士' },
		{ v: '2', l: '本科' },
		{ v: '3', l: '大专' },
		{ v: '4', l: '中专或高中' },
		{ v: '5', l: '初中及以下' },
		{ v: '6', l: '中专' },
		{ v: '7', l: '高中' },
		{ v: '8', l: '初中' },
		{ v: '99', l: '其他' },
	] as CodeItem[],
	SOCIALINSURANCEINDI: [
		{ v: 'Y', l: '有社保' },
		{ v: 'N', l: '无社保' },
	] as CodeItem[],
	ANN_PAY_FREQ: [
		{v: '1', l: '年领'},
		{v: '2', l: '半年领'},
		{v: '3', l: '季领'},
		{v: '4', l: '月领'},
		{v: '5', l: '趸领'},
		{v: '6', l: '转年金'},
	] as CodeItem[],
	SB_PAY_MODE: [
		{ v: '1', l: '累积生息' },
		{ v: '2', l: '现金领取' },
		{ v: '3', l: '银行转账' },
		{ v: '4', l: '抵交保费' },
		{ v: '6', l: '转入万能账户' },
	] as CodeItem[],
    CB_PAY_MODE: [
        { v: '1', l: '现金给付' },
        { v: '2', l: '增额交清' },
        { v: '3', l: '抵交保费' },
        { v: '4', l: '保存生息' },
        { v: '5', l: '不分红' },
        { v: '6', l: '转入万能账户' },
        { v: '7', l: '银行转账' },
    ] as CodeItem[],
    INCOME_SOURCE: [
        { v: '1', l: '工资' },
        { v: '2', l: '房屋出租' },
        { v: '3', l: '投资收益' },
        { v: '4', l: '个体经营' },
        { v: '5', l: '农业收入' },
        { v: '6', l: '其他' },
        { v: '7', l: '私营' },
        { v: '8', l: '银行利息' },
        { v: '9', l: '自由职业收入' },
    ] as CodeItem[],
} as CODES;
