//都使用新的sdk
import * as DefaultGluttonRecorder from './glutton-saas-cmd2.rr.min';

const getGluttonRecorder = (): any => {
	return DefaultGluttonRecorder.default;
}

const GluttonRecorder = getGluttonRecorder();

export default GluttonRecorder;
