/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type PATHS = {

	readonly DESK_SIGN: string;
	readonly DESK_SIGN_IN: string;
	readonly DESK_SIGN_UP: string;
	readonly DESK_HOME: string;
	readonly DESK_NOT_FOUND: string;

	readonly MOBILE_SIGN: string;
	readonly MOBILE_SIGN_IN: string;
	readonly MOBILE_SIGN_UP: string;

	readonly MOBILE_HOME: string;
	readonly MOBILE_NOT_FOUND: string;
	readonly MOBILE_DEMO: string;

	readonly MOBILE_POLICY: string;
	readonly MOBILE_POLICY_ADVICE: string;
	readonly MOBILE_POLICY_QUOTE: string;
	readonly MOBILE_POLICY_QUOTE_DIRECT: string;
	readonly MOBILE_POLICY_INFO: string;
	readonly MOBILE_POLICY_INFO_READONLY: string;
	readonly MOBILE_POLICY_INFO_SHAREONLY: string;
	readonly MOBILE_POLICY_VIEW: string;
	readonly MOBILE_POLICY_VIEW_BYQUOTE: string;
	readonly MOBILE_POLICY_VIEW_READONLY_BYQUOTE: string;
	readonly MOBILE_POLICY_ADVICE_READONLY: string;
	readonly MOBILE_POLICY_ADVICE_TO_QUOTE: string;
	readonly MOBILE_POLICY_ADVICE_TO_QUOTE_NO_ID: string;
	readonly MOBILE_POLICY_ADVICE_DIRECT: string;
	readonly MOBILE_POLICY_APPLY_PENDING: string;
	readonly MOBILE_POLICY_APPLY_SUCCESS: string;
	readonly MOBILE_POLICY_INSURED_CALLBACK: string;
	readonly MOBILE_POLICY_HOLDER_CALLBACK: string;
	readonly MOBILE_POLICY_PAY_SUCCESS: string;
	readonly MOBILE_POLICY_PAY_TRADING: string;
	readonly MOBILE_POLICY_APPLY_FAIL: string;
	readonly MOBILE_POLICY_WECHAT_MINI_TRANSITION: string;
	readonly MOBILE_POLICY_SIGNATURE: string;

	readonly MALL_INDEX: string;
	readonly MALL_HOME: string;
	readonly MALL_ME: string;
	readonly MALL_POLICY: string;
	readonly MALL_KEYWORD_PRODUCTS: string;
	readonly MALL_PROMOTION_PRODUCTS: string;
	readonly MALL_TAG_PRODUCTS: string;
	readonly MALL_MOBILE_SIGN_IN: string;
	readonly MALL_POLICY_FOR_SHARE: string;
	readonly MALL_TOC: string;
	readonly Mall_TOC_ICON: string;
	readonly MALL_TOC_PERSONALINFO: string;
	readonly MALL_TOC_PERSONALINFO_DETAIL: string;
	readonly MALL_TOC_UNBOUND_MOBILENO: string;
	readonly MALL_TOC_POLICY: string;
	readonly MALL_TOC_ORDER: string;
	readonly MALL_APPOINT: string;
	readonly MALL_JSCN: string;
	readonly MALL_JSCN_ORDER: string;

	readonly MALL_MOBILE_GW_SIGN_IN: string;
	readonly MALL_GW_HOME: string;
	readonly MALL_GW_ME: string;
	readonly MOBILE_RECOGNIZE: string;
	readonly MOBILE_RECOGNIZE_DETECT: string;
	readonly MOBILE_RECOGNIZE_DETECT_LIVING: string;
	readonly MOBILE_RECOGNIZE_DETECT_RESULT: string;

	readonly MOBILE_CAMERA: string;
	readonly MOBILE_CAMERA_DETECT: string;

	readonly  MOBILE_VERIFICATION_DETECT: string;

	readonly BUSINESS_HOME: string;
	readonly MOBILE_POLICY_INFO_SHOW: string;
};

export default {
	DESK_SIGN: '/desk/sign',
	DESK_SIGN_IN: '/desk/sign/in',
	DESK_SIGN_UP: '/desk/sign/up',
	DESK_HOME: '/desk',
	DESK_NOT_FOUND: '/desk/404',

	// 手机登录
	MOBILE_SIGN: '/mobile/sign',
	MOBILE_SIGN_IN: '/mobile/sign/in',
	MOBILE_SIGN_UP: '/mobile/sign/up',

	// 手机

	// 手机首页
	MOBILE_HOME: '/mobile',
	MOBILE_NOT_FOUND: '/mobile/404',
	MOBILE_DEMO: '/mobile/demo',

	MOBILE_POLICY: '/mobile/policy',
	// 手机各种订单列表
	MOBILE_POLICY_ADVICE: '/mobile/policy-:vendorCodeRoute/advice/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_ADVICE_DIRECT: '/mobile/policy-:vendorCodeRoute/advice/:vendorCode/:productCode/:productVersion/direct',
	MOBILE_POLICY_QUOTE: '/mobile/policy-:vendorCodeRoute/quote/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_QUOTE_DIRECT: '/mobile/policy-:vendorCodeRoute/quote/:vendorCode/:productCode/:productVersion/direct',
	MOBILE_POLICY_INFO: '/mobile/policy-:vendorCodeRoute/info/:vendorCode/:productCode/:productVersion',
	// 手机各种产品页面
	MOBILE_POLICY_INFO_READONLY: '/mobile/policy-:vendorCodeRoute/info/ro/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_INFO_SHAREONLY: '/mobile/policy-:vendorCodeRoute/info/so/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_VIEW: '/mobile/policy-:vendorCodeRoute/view/:policyId',
	MOBILE_POLICY_VIEW_BYQUOTE: '/mobile/policy-:vendorCodeRoute/view/byquote/:quoteNo',
	MOBILE_POLICY_VIEW_READONLY_BYQUOTE: '/mobile/policy-:vendorCodeRoute/view/ro/byquote/:quoteNo',
	MOBILE_POLICY_ADVICE_READONLY: '/mobile/policy-:vendorCodeRoute/advice/ro/:quoteNo',
	MOBILE_POLICY_ADVICE_TO_QUOTE: '/mobile/policy-:vendorCodeRoute/advice/toquote/:quoteNo',
	MOBILE_POLICY_ADVICE_TO_QUOTE_NO_ID: '/mobile/policy-:vendorCodeRoute/advice/toquote',
	MOBILE_POLICY_APPLY_PENDING: '/mobile/policy-:vendorCodeRoute/apply-pending',
	MOBILE_POLICY_APPLY_SUCCESS: '/mobile/policy-:vendorCodeRoute/apply-success',
	MOBILE_POLICY_INSURED_CALLBACK: '/mobile/policy-:vendorCodeRoute/insuredCallBack/:quoteNo',
	MOBILE_POLICY_HOLDER_CALLBACK: '/mobile/policy-:vendorCodeRoute/holderCallBack/:quoteNo',
	MOBILE_POLICY_PAY_SUCCESS: '/mobile/policy-:vendorCodeRoute/pay-success/:quoteNo',
	MOBILE_POLICY_PAY_TRADING: '/mobile/policy-:vendorCodeRoute/pay-trading/:quoteNo',
	MOBILE_POLICY_APPLY_FAIL: '/mobile/policy-:vendorCodeRoute/apply-fail',
	MOBILE_POLICY_WECHAT_MINI_TRANSITION: '/mobile/policy-:vendorCodeRoute/wechat-mini-transition',
	MOBILE_POLICY_SIGNATURE: '/mobile/policy-:vendorCodeRoute/signature/:signatureId',


	MALL_INDEX: '/mall',
	MALL_HOME: '/mall/home',
	MALL_ME: '/mall/me',
	MALL_POLICY: '/mall/policy',
	MALL_KEYWORD_PRODUCTS: '/mall/products/keyword/:keyword',
	MALL_PROMOTION_PRODUCTS: '/mall/products/promotion',
	MALL_TAG_PRODUCTS: '/mall/products/tag/:tag',
	MALL_MOBILE_SIGN_IN: '/mall/sign/in',
	MALL_POLICY_FOR_SHARE: '/mall/services/share-validate/:quoteNo',
	MALL_TOC: '/mall/toC',
	Mall_TOC_ICON: '/mall/toCIcon',
	MALL_TOC_PERSONALINFO: '/mall/tocPersonalInfo',
	MALL_TOC_PERSONALINFO_DETAIL: '/mall/tocPersonalInfoDetail',
	MALL_TOC_UNBOUND_MOBILENO: '/mall/unboundMobileNo',
	MALL_TOC_POLICY: '/mall/tocpolicy',
	MALL_TOC_ORDER: '/mall/tocorder',
	MALL_JSCN: '/mall/jscn',
	MALL_JSCN_ORDER: '/mall/jscnOrder',
	MALL_APPOINT: '/mall/appoint',

	MALL_MOBILE_GW_SIGN_IN: '/mall/gw/sign/in',
	MALL_GW_HOME: '/mall/gw/home',
	MALL_GW_ME: '/mall/gw/me',

	MOBILE_RECOGNIZE: '/mobile/recognize',
	MOBILE_RECOGNIZE_DETECT: '/mobile/recognize/detect/:quoteNo/:name/:cardId/:userType',
	MOBILE_RECOGNIZE_DETECT_LIVING: '/mobile/recognize/living/:quoteNo/:name/:cardId',
	MOBILE_RECOGNIZE_DETECT_RESULT: '/mobile/recognize/result/:result',

	MOBILE_CAMERA: '/mobile/camera',
	MOBILE_CAMERA_DETECT: '/mobile/camera/:quotationNo/:idType',

	MOBILE_VERIFICATION_DETECT: '/mobile/verification/:shareRecordNo',


	BUSINESS_HOME: '/business/:tenantCode/:channelCode/home',
	MOBILE_POLICY_INFO_SHOW: '/mobile/policy-:vendorCodeRoute/info/show/:vendorCode/:productCode/:productVersion/:tenantCode',
} as PATHS;
