type POSITION = {
	left: number;
	top: number;
};

class DOM {
	private SLIDE_SHARD_TIMES = 30;
	SLIDE_TRANSITION_TIME = 400;

	outerHeight(el: HTMLElement, includesMargin = false): number {
		const style = getComputedStyle(el);
		return (
			el.offsetHeight +
			(includesMargin ? parseInt(style.marginTop || '0') + parseInt(style.marginBottom || '0') : 0)
		);
	}
	outerWidth(el: HTMLElement, includesMargin = false): number {
		const style = getComputedStyle(el);
		return (
			el.offsetWidth +
			(includesMargin ? parseInt(style.marginLeft || '0') + parseInt(style.marginRight || '0') : 0)
		);
	}
	/**
	 * 相对位置
	 */
	position(el: HTMLElement): POSITION {
		return { left: el.offsetLeft, top: el.offsetTop };
	}
	/**
	 * 绝对位置
	 */
	offset(el: HTMLElement): POSITION {
		const rect = el.getBoundingClientRect();
		return {
			top: rect.top + window.scrollY,
			left: rect.left + window.scrollX
		};
	}
	slideDown(node: HTMLElement | null, originalDisplay: string = 'block'): this {
		if (node == null) {
			return this;
		}

		const style = getComputedStyle(node);
		let { display, height, maxHeight, gridTemplateColumns } = style;

		// 侦测是否是Grid布局
		// RESEARCH 如何侦测flex布局?
		if (gridTemplateColumns !== 'none') {
			originalDisplay = 'grid';
		}

		if (display !== 'none' && parseFloat(height || '0') !== 0 && parseFloat(maxHeight || '0') !== 0) {
			// 已经在展示, 不做任何事情
			return this;
		}

		node.style.visibility = 'hidden';
		if (display === 'none') {
			node.style.display = originalDisplay;
		}
		node.style.maxHeight = 'unset';
		node.style.height = 'auto';

		const h = this.outerHeight(node, true);
		let maxH = parseFloat(maxHeight || '0');
		if (isNaN(maxH)) {
			maxH = Infinity;
		}

		node.style.height = '0';
		node.style.maxHeight = '';
		node.style.visibility = '';
		node.style.overflowY = 'hidden';
		let startHeight = 0;
		const endHeight = Math.min(h, maxH);
		const times = this.SLIDE_SHARD_TIMES;
		const increase = endHeight / times;
		const interval = this.SLIDE_TRANSITION_TIME / times;
		const slide = (index: number, count: number) => {
			node.style.height = `${startHeight}px`;
			startHeight += increase;
			if (index === count) {
				node.style.overflowY = '';
				node.style.height = '';
			}
		};
		for (let index = 1, count = times + 1; index <= count; index++) {
			setTimeout(() => slide(index, count), index * interval);
		}
		return this;
	}
	slideUp(node: HTMLElement | null): this {
		if (node == null) {
			return this;
		}

		// 获取当前显示方式
		const style = getComputedStyle(node);
		let { display } = style;
		// 获取当前高度
		const h = this.outerHeight(node, true);
		// 设置隐藏内容
		node.style.overflowY = 'hidden';
		// 强制设置当前显示方式, 以免被代码CSS影响
		node.style.display = display;

		let startHeight = h;
		const times = this.SLIDE_SHARD_TIMES;
		const decrease = startHeight / times;
		const interval = this.SLIDE_TRANSITION_TIME / times;

		const slide = (index: number, count: number) => {
			node.style.height = `${startHeight}px`;
			startHeight -= decrease;
			if (index === count) {
				// 强制设置隐藏
				node.style.display = 'none';
				// 清除高度和内容隐藏
				node.style.height = '';
				node.style.overflowY = '';
			}
		};
		for (let index = 1, count = times + 1; index <= count; index++) {
			setTimeout(() => slide(index, count), index * interval);
		}
		return this;
	}
	scrollToTop(): this {
		window.scrollTo(0, 0);
		return this;
	}
	/**
	 * 始终让child在parent的显示范围内.
	 * 1. parent需要支持scroll
	 * 2. child和parent均为jquery对象
	 */
	scrollIntoView(child: HTMLElement, parent: HTMLElement): void {
		const parentOffset = this.offset(parent);
		const childOffset = this.offset(child);

		const parentBottom = parentOffset.top + this.outerHeight(parent);
		const childBottom = childOffset.top + this.outerHeight(child);
		if (childBottom > parentBottom) {
			// 下方被遮住
			// 增加20像素以免正好贴底
			parent.scrollTo(0, parent.scrollTop + childBottom - parentBottom + 20);
		}
		if (childOffset.top < parentOffset.top) {
			// 上方被遮住
			parent.scrollTo(0, parent.scrollTop - parentOffset.top + childOffset.top - 40);
		}
	}
}

export default new DOM();
