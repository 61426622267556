import { Utils, Storage } from '../../common';
import { ChannelName, PolicyStepName } from '../../types';
import { putGluttonContext, GluttonConstans, initGluttonRecorder } from './glutton-util';


//可回朔辅助类
class GluttonHelper {

    /**
     * 
     * @param step 转换成中文的step
     */
    transformChnStepName = (step: string) => {
        switch (true) {
            case step === PolicyStepName.STEP_INTRO:
                return '产品详情';
            case step === PolicyStepName.STEP_ADVICE_PARTICIPANTS:
                return '计划书投被保人信息收集';
            case step === PolicyStepName.STEP_ADVICE_COVEAGES:
                return '计划书险种制定';
            case step === PolicyStepName.STEP_ADVICE:
                return '计划书';
            case step === PolicyStepName.STEP_NOTICE:
                return '投保须知';
            case step === PolicyStepName.STEP_PARTICIPANTS:
                return '投被保人信息收集';
            case step === PolicyStepName.STEP_COVEAGES:
                return '险种制定';
            case step === PolicyStepName.STEP_HOLDER_FILL:
                return '投保人信息';
            case step === PolicyStepName.STEP_PERSON_INFO:
                return '投保人信息';
            case step === PolicyStepName.STEP_INSUREDS_FILL:
                return '被保人信息';
            case step === PolicyStepName.STEP_DATA_ENTRY:
                return '投被保人受益人信息录入';
            case step === PolicyStepName.STEP_HOLDER_DECLARATIONS:
                return '投保人告知';
            case step === PolicyStepName.STEP_INSURED_DECLARATIONS:
                return '被保险人告知';
            case step === PolicyStepName.STEP_IMAGE_UPLOAD:
                return '上传影像信息';
            case step === PolicyStepName.GENERATION_CHECK_VIEW:
                return '上传影像信息';                
            case step === PolicyStepName.STEP_REVIEW_ORDER:
                return '核对订单';
            case step === PolicyStepName.STEP_APPLY_CONFIRM:
                return '核对订单';
            case step === PolicyStepName.STEP_PAYMENT:
                return '支付保费';
            case step === PolicyStepName.STEP_UNDERWRITE_FAILED:
                return '提交结果';//return '核保失败';
            case step === PolicyStepName.STEP_APPLY_FAILED:
                return '提交结果';//return '投保失败';
            case step === PolicyStepName.STEP_ISSUE_FAILED:
                return '提交结果';//return '承保失败';
            case step === PolicyStepName.STEP_PAY_FAILED:
                return '提交结果';//return '支付失败';
            case step === PolicyStepName.STEP_PAY_SUBMIT_SUCCESS:
                return '支付结果';//return '支付成功';
            case step === PolicyStepName.STEP_ISSUE_SUCCESS:
                return '提交结果';//return '承保成功';
            case step === PolicyStepName.STEP_POLICY_VIEW:
                return '订单/保单详情';
            case step === PolicyStepName.STEP_ORDER_DETAILS:
                return '订单/保单详情';
            case step === PolicyStepName.STEP_POLICY_INFO:
                return '订单/保单详情';
            case step === PolicyStepName.STEP_APPLY_PEDDING:
                return '投保轮训';
            case step === PolicyStepName.STEP_SMS_VERIFICATION:
                return '短信验证';
            case step === PolicyStepName.STEP_HEALTH_NOTICE:
                return '被保险人健康告知';
            case step === PolicyStepName.STEP_OUTLINE_PARTICIPANTS:
                return '投被保人信息录入';
            case step === PolicyStepName.STEP_SURRENDER_CALCULATE:
                return '退保试算';
            case step === PolicyStepName.STEP_SURRENDER_CONFIRM:
                return '退保确认';
            case step === PolicyStepName.STEP_SURRENDER_SUCCESS:
                return '退保结果';
            case step === PolicyStepName.STEP_SURRENDER_FAIL:
                return '退保结果';   
            case step === PolicyStepName.STEP_SURRENDER_INFO:
                return '退保详情';
            default:
                return step;
        }
    }


    initTraceInfo(options?: {
        traceNo?: string;
        productName?: string;
        rootVendorName?: string;
        orderNo?: string;
        policyNo?: string;
        allSteps?: string[];
        currentStep: string;
        policyHolderName?: string;
        policyHolderIdNo?: string;
        policyHolderMobile?: string;
        channel?: string;
        sourceFactor?: string;// 新增版本控制，来源因子
        gluttonVersion?: string;// 可回溯版本号
        type?:string;//新增可回溯流程区分 1 新保 2 退保 3 加减人 新保时可不传
    }) {
        const allSteps = Utils.toArray(options?.allSteps);
        putGluttonContext(GluttonConstans.ALL_STEPS, allSteps);
        putGluttonContext(GluttonConstans.CURRENT_STEP, options!.currentStep);
        //tranceNo放在queryString上，ios无法获取到,
        let traceNo = Utils.fromQueryString().gluttonTraceNo;
        // let traceNo = Storage.GluttonData.get(Consts.GLUTTON_TRACE_NO);
        if (!Utils.isBlank(traceNo)) {
            putGluttonContext(GluttonConstans.TRACE_NO, traceNo);
            //用完及时销毁
            Storage.GluttonData.session().clear();
        } else {
            //queryString上不存在则使用传递过来的
            const passTraceNo = options?.traceNo;
            if (!Utils.isBlank(passTraceNo)) {
                putGluttonContext(GluttonConstans.TRACE_NO, passTraceNo);
            }
        }
        if (!Utils.isBlank(options?.productName)) {
            putGluttonContext(GluttonConstans.PRODUCT_NAME, options?.productName);
        }
        if (!Utils.isBlank(options?.rootVendorName)) {
            putGluttonContext(GluttonConstans.VERDOR_NAME, options?.rootVendorName);
        }
        if (!Utils.isBlank(options?.orderNo)) {
            putGluttonContext(GluttonConstans.ORDER_NO, options?.orderNo);
        }
        if (!Utils.isBlank(options?.policyNo)) {
            putGluttonContext(GluttonConstans.POLICY_NO, options?.policyNo);
        }
        if (!Utils.isBlank(options?.policyHolderName)) {
            putGluttonContext(GluttonConstans.POLICY_HOLDER_NAME, options?.policyHolderName);
        }
        if (!Utils.isBlank(options?.policyHolderIdNo)) {
            putGluttonContext(GluttonConstans.POLICY_HOLDER_ID_NO, options?.policyHolderIdNo);
        }
        if (!Utils.isBlank(options?.policyHolderMobile)) {
            putGluttonContext(GluttonConstans.POLICY_HOLDER_MOBILE, options?.policyHolderMobile);
        }
        if (!Utils.isBlank(options?.channel)) {
            putGluttonContext(GluttonConstans.SOURCE, options?.channel);
        }
        if (!Utils.isBlank(options?.sourceFactor)) {
            putGluttonContext(GluttonConstans.SOURCE_FACTOR, options?.sourceFactor);
        }
        if (!Utils.isBlank(options?.type)) {
            putGluttonContext(GluttonConstans.TYPE, options?.type);
        }
        putGluttonContext(GluttonConstans.VERSION, options?.gluttonVersion || 'v1');
    }

    generateGluttonSourceFactor(options: {
        env?: string;
        vendorCode?: string;
        tenantCode?: string;
    }): string {
        if (!Utils.isBlank(options?.env) && !Utils.isBlank(options?.vendorCode)) {
            return options?.env + '_' + options?.vendorCode;
        }
        return '';
    }

    registerGlutton(options: {
        vendorCode: string;
        productCode: string;
        productVersion: string;
    }): Promise<void> {
        return new Promise(async (resolve, reject) => {
            // @ts-ignore
            if (!window.gluttonRecorder) {
                // @ts-ignore
                window.gluttonRecorder = await initGluttonRecorder(options);
            }
            resolve();
        })
    }

    transformChannelName = (channelCode: string) => {
        switch (true) {
            case channelCode === ChannelName.DEFAULT_APP:
                return 'E保电投';
            case channelCode === ChannelName.ESHOP:
                return 'E保网销商城';
            case channelCode === ChannelName.JSCN:
                return '江苏有线';
            case channelCode === ChannelName.WECHAT:
                return '永保';
            case channelCode === ChannelName.OFFICIALWEB:
                return '官网';
            default:
                return channelCode;
        }
    }

}

export default new GluttonHelper();

