/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type ERRORS = {
	readonly PRODUCT_IS_DISABLED: string;
	readonly PRODUCT_AGREEMENT_NOT_EXISTS: string;
	readonly PRODUCT_NOT_EXISTED: string;
	// 弘康特有, 进入短信验证页面
	readonly HONGKANG_TO_SMS_VALIDATE_PAGE: string;
	[propName: string]: string;
};
const errors = {
	PRODUCT_IS_DISABLED: '产品已下架, 请线下确认.',
	PRODUCT_AGREEMENT_NOT_EXISTS: '产品合约未生效, 请线下确认.',
	PRODUCT_NOT_EXISTED: '产品没有找到, 请线下确认.',
	HONGKANG_TO_SMS_VALIDATE_PAGE: 'sign'
} as ERRORS;
export default errors;
export const registerError = (code: string, description: string): void => {
	errors[code] = description;
};
